import VueRouter from 'vue-router'

import start from '../views/start.vue';
import scan from '../views/scan.vue';
import select from '../views/select.vue';
import override from '../views/override.vue';

const routes = [
  {
    path: '/',
    component: start,
  },
  {
    path: '/scan',
    component: scan,
  },
  {
    path: '/select',
    component: select,
  },
  {
    path: '/override',
    component: override,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
