import moment from "moment-timezone";

export default function toDateHuman(date) {
  if (date) {
    let m = moment.tz(date, 'UTC');
    let adjusted = m.tz(moment.tz.guess()).fromNow();
    return adjusted;
  } else {
    return;
  }
}