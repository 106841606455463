<template>
    <div>
        <div class="header">
<!--            <div class="back" @click="back">-->
<!--                <div class="icon">-->
<!--                    <awesome-icon icon="chevron-left"/>-->
<!--                </div>-->
<!--            </div>-->

            <div class="text"> Locations </div>

        </div>
        <div class="items">
            <div class="item" v-for="item in category.items" @click="$emit('select',item)">
                <div class="thumbnail">
                    <img :src="getVideoThumbnail(item)"/>
                </div>
                <div class="info">
                    <div class="name">
                        {{ getMetadataValue(item, 'MediaItem.name', item.name) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-category",

  props: {
    category: {}
  },

  methods: {
    back() {
      this.$router.replace({path: 'select'})
    },

    getVideoThumbnail(item) {
      return `${process.env.VUE_APP_URL_API}/thumbnails-media/${item.id}/thumb-${item.id}.png`;
    },

    getMetadataValue(media, name, placeholder = 'none') {
      if (media.metadata === null) {
        return placeholder
      }

      let meta = JSON.parse(media.metadata);

      let metadata = meta.find(x => x['metadata']['name'] === name)
      if (!metadata) {
        return placeholder
      }

      // String Metadata
      if (metadata.metadata.valueType === 'ANY' && metadata.metadata.datatype === 'STRING') {
        return metadata.value
      }

      // Integer Metadata
      if (metadata.metadata.valueType === 'ANY' && metadata.metadata.datatype === 'INTEGER') {
        return metadata.value
      }

      // Picklist Metadata
      if (metadata.metadata.valueType === 'PICKLIST' && metadata.metadata.datatype === 'STRING') {
        if (!metadata.metadata.predefinedValues) {
          return placeholder;
        }
        let val = metadata.metadata.predefinedValues.find(x => x.id === parseInt(metadata.value))
        if (!val) {
          return placeholder;
        } else {
          return val.value
        }
      }

    },
  }
}
</script>

<style scoped lang="scss">

.header {
    padding: 2em 1.5em 0.5em;
    position: relative;

    .back {
        position: absolute;
        left: 0;
        top: 0;
        padding: 2em 1.5em 0.5em;

        .icon {
            font-size: 1.2em;
            //color: #fff;
        }
    }

    .text {
        font-size: 1.5em;
        //color: #fff;
        font-weight: 600;
        text-align: center;
        text-transform: capitalize;
    }
}

.items {
    padding: 1.5em 1.5em;

    .item {
        display: flex;
        margin-bottom: 1em;
        font-size: 1.5em;

        .thumbnail {
            background: rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
            box-sizing: border-box;
            width: 3em;
            height: 3em;
            margin-right: 0.8em;
            //color: #fff;
            flex-shrink: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .info {
            //color: #fff;
            text-transform: capitalize;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 0.7em;
        }

    }
}

</style>
