<template>
    <div class="scanner" @click="$emit('close')">
        <div class="video-container">
            <div class="starting" v-if="!ready">
                <div class="text" v-if="!permissionDenied">Starting camera</div>
                <div class="text" v-else>Permissions required</div>
            </div>
            <div class="instruction" v-if="ready">
                <div class="text">Scan <span class="thicc">QR</span> code</div>
            </div>
            <video v-show="!permissionDenied" autoplay loop muted playsinline ref="video"
                   :poster="require('@/img/poster.png')"></video>
            <div class="permissions-denied" v-if="permissionDenied">
                <div class="text">The camera permissions have been denied or failed.
                    <br/><br/>Please check the website permissions to allow camera access and refresh the page.
                </div>
                <div class="btn">
                    <div class="button" @click="refresh">Refresh</div>
                </div>
            </div>
        </div>
        <canvas ref="canvas" height="480" width="640"></canvas>
    </div>
</template>

<script>
import jsQR from "jsqr";

export default {
  name: "qr-scanner-inline",
  data() {
    return {
      stream: undefined,
      video: undefined,
      canvasElement: undefined,
      canvas: undefined,
      ready: false,
      found: false,
      hitPrevious: undefined,
      hitCount: 0,
      permissionDenied: false,
    }
  },

  mounted() {

    // try {
    //   if (cordova) {
    //     let permissions = cordova.plugins.permissions;
    //     permissions.hasPermission(permissions.CAMERA, (() => {
    //       console.log('good to go')
    //     }), () => {
    //       this.requestPermission();
    //     });
    //   }
    // } catch {
    //   console.log('Not cordova i hope')
    // }


    this.video = this.$refs.video;
    this.canvasElement = this.$refs.canvas;
    this.canvas = this.canvasElement.getContext('2d');

    this.initiate();

  },

  beforeDestroy() {
    this.found = true;
    this.stopMedia();
  },

  methods: {

    // requestPermission() {
    //   let permissions = cordova.plugins.permissions;
    //   permissions.requestPermission(permissions.CAMERA, () => {
    //     console.log('granted camera permission');
    //   }, () => {
    //     this.$notify.error('Require camera access');
    //     this.$emit('noPermission')
    //   });
    // },

    refresh() {
      window.location.reload();
    },

    stopMedia() {
      if (this.stream) {
        this.stream.getTracks().forEach(track => {
          track.stop();
        })
      }
    },

    initiate() {
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment'
        }
      })
        .then(stream => {
          this.stream = stream;
          this.video.srcObject = this.stream;
          this.video.play();
          requestAnimationFrame(this.tick);
        })
        .catch(err => {
          console.log(err);
          this.permissionDenied = true;
        })
    },

    tick() {

      if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
        this.ready = true;
        this.canvas.drawImage(this.video, 0, 0, this.canvasElement.width, this.canvasElement.height);
        let imageData = this.canvas.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height);

        let code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert'
        });
        if (code) {
          this.processFrame(code.data);
        }
      }
      if (this.found === false) {
        requestAnimationFrame(this.tick);
      }
    },

    processFrame(data) {
      if (data === this.hitPrevious) {
        if (this.hitCount < this.$store.state.scannerHitThreshold) {
          this.hitCount++;
        } else {
          this.found = true;
          this.$emit('scanned', data);
        }
      } else {
        this.hitPrevious = data;
        this.hitCount = 0;
      }
    }
  }
}
</script>

<style scoped lang="scss">

.starting,
.instruction {
    /*width: 100%;*/
    margin-bottom: 1em;
    text-align: center;
}

.starting .text,
.instruction .text {
    /*font-size: 0.8em;*/
    /*font-weight: 700;*/
    /*text-transform: uppercase;*/
}

video {
    width: 100%;
    border-radius: 0.3em;
    background: #111;
}

canvas {
    display: none;
}

.video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.permissions-denied {
    .text {
        border-left: 2px solid $color-primary;
        padding: 0.5em 1em;
        font-size: 0.8em;

    }

    .btn {
        margin-top: 1em;
        text-align: center;
    }
}


</style>