<template>
    <div class="page">
        <div class="header">
            <sc-logo style="font-size:1.2em;text-align: center" :logo="true"></sc-logo>
        </div>
        <div class="start-screen">
            <div class="widget">
                <div class="description">
                    Scan a <span class="thicc">QR</span> code to connect
                </div>
                <div class="buttons">
                    <div class="button" @click="$router.push('/scan')">
                        Open Camera
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BfHeader from "@/components/bf-header";
import ScLogo from "@/components/sc-logo";

export default {
  name: "start",
  components: {ScLogo, BfHeader},
  data() {
    return {}
  },

  computed: {},

  mounted() {

  },

  methods: {}
}
</script>

<style scoped lang="scss">

.page {
    //background-color: #0F1C2B;
    //background-image: url('~@/img/triangles.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 130% auto;
}

.header {
    background: #fff;
    padding: 1em 0;
    font-size: 2em;
  box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.1);
}

.start-screen {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1em;
    font-size: 1.2em;

  .widget {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }

    .description {

    }

    .buttons {
        margin-top: 1em;
        text-align: center;
    }
}
</style>