<template>
    <div class="mouse-container" ref="mouse">
        <div class="icon">
            <div class="icon-inner">
                <awesome-icon icon="mouse"></awesome-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "motion-mouse",

  data() {
    return {

      moveInterval: null,
      moveIntervalTime: 100, // in ms
      moveMultiplier: 1.3,
      scrollMultiplier: -1.5,

      moveCountX: 0,
      moveCountY: 0,
      touches: 0,
      movePreviousTouch: null,
    }
  },

  created() {
    if (process.env.VUE_APP_MOUSE_MOVE_INTERVAL && Number.isInteger(parseInt(process.env.VUE_APP_MOUSE_MOVE_INTERVAL))) {
      this.moveIntervalTime = parseInt(process.env.VUE_APP_MOUSE_MOVE_INTERVAL);
    }
    if (process.env.VUE_APP_MOUSE_MOVE_MULTIPLIER && Number.isInteger(parseInt(process.env.VUE_APP_MOUSE_MOVE_MULTIPLIER))) {
      this.moveMultiplier = parseInt(process.env.VUE_APP_MOUSE_MOVE_MULTIPLIER);
    }
    if (process.env.VUE_APP_MOUSE_SCROLL_MULTIPLIER && Number.isInteger(parseInt(process.env.VUE_APP_MOUSE_SCROLL_MULTIPLIER))) {
      this.scrollMultiplier = parseInt(process.env.VUE_APP_MOUSE_SCROLL_MULTIPLIER);
    }
  },


  mounted() {
    if (this.$refs.mouse) {
      this.$refs.mouse.addEventListener('touchmove', this.mouseMoveEvent)
      this.$refs.mouse.addEventListener('touchend', this.mouseMoveEndEvent)
      this.$refs.mouse.addEventListener('click', this.mouseClickEvent)
    }

    this.moveInterval = setInterval(() => {
      this.moveDebounce()
    }, this.moveIntervalTime)
  },

  beforeDestroy() {
    if (this.$refs.mouse) {
      this.$refs.mouse.removeEventListener('touchmove', this.mouseMoveEvent)
      this.$refs.mouse.removeEventListener('touchend', this.mouseMoveEndEvent)
      this.$refs.mouse.removeEventListener('click', this.mouseClickEvent)
    }
    clearInterval(this.moveInterval);
  },

  methods: {

    mouseClickEvent(e) {
      this.$emit('mouse', {
        action: 'click',
      })
    },

    mouseMoveEvent(e) {
      e.preventDefault();

      const touch = e.touches[0];

      if (this.previousTouch) {
        e.movementX = touch.pageX - this.previousTouch.pageX;
        e.movementY = touch.pageY - this.previousTouch.pageY;

        this.moveCountX += e.movementX;
        this.moveCountY += e.movementY;
        this.touches = e.touches.length;
      }

      this.previousTouch = touch;
    },

    mouseMoveEndEvent(e) {
      this.previousTouch = null;
    },

    moveDebounce() {
      if (this.moveCountX === 0 && this.moveCountY === 0) {
        return
      }
      if (this.touches > 1) {
        this.$emit('mouse', {
          action: 'scrollV',
          data: {
            movementX: Math.round(this.moveCountX * this.scrollMultiplier),
            movementY: Math.round(this.moveCountY * this.scrollMultiplier),
          }
        })
      } else {
        this.$emit('mouse', {
          action: 'move',
          data: {
            movementX: Math.round(this.moveCountX * this.moveMultiplier),
            movementY: Math.round(this.moveCountY * this.moveMultiplier),
          }
        })
      }

      this.moveCountX = 0;
      this.moveCountY = 0;
    }
  }
}
</script>

<style scoped lang="scss">
.mouse-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
        border: 1px solid rgba(255, 255, 255, 0.1);
        width: 4em;
        height: 4em;
        border-radius: 10em;
        font-size: 3em;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(255, 255, 255, 0.6);
        position: relative;
        animation: throb 2.5s ease-in-out infinite;
        pointer-events: none;

        @keyframes throb {
            0% {
                transform: scale(1);
            }
            50% {
                transform: scale(1.2);
            }
            100% {
                transform: scale(1);
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: 0.5em;
            top: 0.5em;
            right: 0.5em;
            bottom: 0.5em;
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-radius: 10em;
        }

        &:after {
            content: '';
            position: absolute;
            left: 1em;
            top: 1em;
            right: 1em;
            bottom: 1em;
            border: 1px solid rgba(255, 255, 255, 0.5);
            border-radius: 10em;
        }
    }
}
</style>