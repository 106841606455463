import moment from "moment-timezone";
export default function toDatetime(date) {
  if (date) {
    let m = moment.tz(date, 'UTC');
    let adjusted = m.tz(moment.tz.guess()).format('D-MM-Y');
    return adjusted
  } else {
    return;
  }
}
