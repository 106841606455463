<template>
  <div class="logo">
    <div class="image" :class="{big: onRoot}">
      <img src="@/img/origin-labs.png"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "sc-logo",

  props: {
    logo: {
      default: false,
    }
  },
  computed: {
    onRoot() {
      return this.$route.path === '/';
    }
  }
}
</script>

<style scoped lang="scss">

.logo {

  .image {
    line-height: 1em;
    font-size: 1em;

    display: flex;
    align-items: center;

    img {
      height: 2em;
      object-fit: contain;
      max-width: 80%;
    }
  }

  .image.big {
    justify-content: center;
    img {
      height: initial;
    }
  }

  .name {
    font-weight: 700;
    color: #222;
    letter-spacing: 0.02em;

    .top {
      font-size: 0.7em;
      margin-bottom: 0.3em;
      font-weight: 700;
    }

    .bottom {
      font-size: 1.1em;
      text-transform: uppercase;
    }
  }
}


</style>
