<template>
    <div class="tabs">
        <div class="tab" :class="{active:category === '2'}" @click="select('2')"><span class="name">videos</span></div>
        <div class="tab" :class="{active:category === '1'}" @click="select('1')"><span class="name">All</span></div>
        <div class="tab" :class="{active:category === '3'}" @click="select('3')"><span class="name">scripts</span></div>
    </div>
</template>

<script>
export default {
  name: "sc-tabs",

  data() {
    return {}
  },

  computed: {

    category() {
      return this.$route.query['category']
    }

  },

  mounted() {

  },

  methods: {
    select(category) {
      this.$router.replace({query: {category: category}})
    }
  }
}
</script>

<style scoped lang="scss">
.tabs {
    display: flex;
    background: $color-secondary;
    padding: 1.5em;

    .tab {
        flex: 1;
        flex-shrink: 0;
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1em;
        cursor: pointer;
        margin: 0 1em;

        .name {
            padding: 0.2em;
            position: relative;
            color: #ddd;

            &:after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: -2px;
                right: -2px;
                border-bottom: 2px solid $color-primary;
            }

        }

        &.active .name {
            color: #fff;

            &:after {
                border-bottom: 4px solid $color-primary;
                bottom: -5px;
            }
        }
    }
}
</style>