<template>
    <div class="notification" :class="[notification.type, {fading : fading}]" @click="done()">
        <div class="notification-inner">
            <div class="data">
                <div class="name">
                    <div class="name-inner">
                        {{ notification.title }}
                    </div>
                    <div class="close-button" @click="done()">
                        <awesome-icon class="close-button-icon" icon="times"/>
                    </div>
                </div>
                <div class="value">
                    <div class="code" v-if="(typeof notification.message === 'string')">
                        {{ capitalize(notification.message) }}
                    </div>
                    <div class="code" v-else-if="(typeof notification.message === 'object')">
                        <div class="msg" v-for="(msg,key) in notification.message">
                            <div class="key">{{ key }}</div>
                            <template v-if="typeof msg === 'object'">
                                <div class="m" v-for="m in msg">
                                    {{ m }}
                                </div>
                            </template>
                            <div v-else class="m">{{ msg }}</div>
                        </div>
                    </div>
                </div>
                <div class="timer-bar"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'notification-panel-item',
  props: ['notification'],
  data() {
    return {
      fading: false,
      timeout: null,
    }
  },

  created() {
    this.timer();
  },

  beforeUnmount() {
    clearTimeout(this.timeout)
  },

  methods: {
    timer() {
      this.timeout = setTimeout(() => {
        this.done();
      }, 5000);
    },
    done() {
      this.fading = true;
      this.$emit('done');
    },
    capitalize(str) {
      return (typeof str === 'string') ? str.charAt(0).toUpperCase() + str.slice(1) : str;
    }
  }
}
</script>

<style scoped lang="scss">
.notification {
    padding-bottom: 0.5em;

    .notification-inner {
        box-shadow: 0 0 40px 4px rgba(0, 0, 0, .5);
        border-radius: 0.3em;
        overflow: hidden;
        background: rgba(255, 255, 255, 1);
        //animation: NotifSlideUp 0.3s ease-in-out forwards;
        position: relative;

        cursor: pointer;
        pointer-events: initial;
        font-size: 1em;
    }
}

.notification .name {
    font-weight: 800;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name-inner {
        padding: 0 32px;
    }

    .close-button {
        padding: 1.4em 1.5em;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-size: 0.9em;

        &:hover {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.1);
        }

        .close-button-text {
            font-weight: 600;
            margin-right: 0.3em;
        }
    }
}


.notification.Logged.in .name, .notification.success .name {
    background: #16b029;
}

.notification.error .name {
    background: #ef5959
}

.notification .code {
    //white-space: pre-wrap;
    //word-wrap: break-word
}

.notification .value {
    padding: 20px 50px 22px 32px;
    box-sizing: border-box;
    color: #555;
    font-size: 1.1em;
    font-weight: 500;
}

.notification.fading {
    //animation: NotifFadeOut .3s ease-in-out forwards;
    pointer-events: none;
}

.timer-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background: #eee;
    width: 100%;
    transform: translateX(-100%);
    animation: timer 4.5s ease-in-out 0.5s forwards;
    border-top-right-radius: 4px;
    pointer-events: none;
}

.notification.error .timer-bar {
    background: #ef5959;
}

.notification.success .timer-bar {
    background: #16b029;
}

@keyframes NotifSlideUp {
    from {
        opacity: 0;
    }
    to {
        margin-top: 0;
        opacity: 1;
    }
}

@keyframes NotifFadeOut {
    from {
        opacity: 1
    }
    to {
        //opacity: 0
    }
}

@keyframes timer {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

.msg {

    margin-bottom: 0.8em;

    &:last-child {
        margin: 0;
    }

    .key {
        font-weight: 600;
        margin-bottom: 0.2rem;
    }

    .m {
        font-size: 0.9em;
        margin-bottom: 0.2rem;
    }
}
</style>