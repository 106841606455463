import Vue from 'vue';
import VueRouter from 'vue-router';

import Notify from './plugins/notification-panel'
import store from "@/store";
import PortalVue from 'portal-vue'
import FiltersLoader from './filters'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import Vue2TouchEvents from 'vue2-touch-events'


Vue.use(VueRouter);
Vue.use(PortalVue);
Vue.use(FiltersLoader);
Vue.use(Vue2TouchEvents, {
  swipeTolerance: 60,
})
library.add(fas);
library.add(far);
library.add(fab);
Vue.component('awesome-icon', FontAwesomeIcon);

// Notification panel
Vue.use(Notify, {store});

// import eventController from '~/plugins/event-controller'
// // Event Websocket Controller
// Vue.use(eventController, {
//   websocket_url: process.env.VUE_APP_URL_SOCKET
// });