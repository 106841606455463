<template>
    <div>
        <div class="header" @click="toStart()">
            <sc-logo></sc-logo>

            <transition name="fade-in">
                <div class="connected" v-if="connected" @click.stop>
                    <div class="text">
                        connected
                    </div>
                </div>
            </transition>
        </div>
        <div class="header-spacer"></div>
    </div>
</template>

<script>
import ScLogo from "@/components/sc-logo";

export default {
  name: "bf-header",

  props: {
    connected: {
      default: false,
    },
  },

  components: {ScLogo},
  methods: {
    toStart() {
      this.$router.push({path: '/', query: {}})
    }
  }
}
</script>

<style scoped lang="scss">

.header {
    display: flex;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5em;
    box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.1);
    background: #fff;
    box-sizing: border-box;
}

.header, .header-spacer {
    height: 4em;
}

.fade-in-enter-active {
    transition: all 0.4s ease-in-out;

    .text {
        transition: all 0.5s ease-in-out;
    }
}

.fade-in-enter .text {
    opacity: 0;
}

.connected {
    background: $color-primary;
    color: #fff;
    font-weight: 700;

    text-transform: uppercase;
    animation: appear 0.5s ease-in-out 1.5s forwards;

    position: fixed;
    top: 4em;
    height: calc(100% - 4em);
    width: 101%;
    right: -1px;
    display: flex;
    justify-content: center;
    align-items: center;

    //border-radius: 10em;

    .text {
        box-sizing: border-box;
        animation: pulse 0.5s ease-in-out 1.5s forwards;
        font-size: 1.8em;
        text-align: center;
    }
}

@keyframes appear {
    60% {
        border-radius: 0;
    }
    100% {
        width: 7em;
        height: 2em;
        top: 1em;
        right: 1.5em;
        border-radius: 10em;
    }
}

@keyframes pulse {
    //0% {
    //    transform: scale(1);
    //}
    //50% {
    //    transform: scale(1.1);
    //}
    //70% {
    //    transform: scale(1.1);
    //}
    100% {
        font-size: 0.7em;
    }
}
</style>